import React from 'react';
import "./index.scss";

function Rolling() {
    return (
        <div className="Rolling">
            <p>Applications are rolling, so apply now!</p>
        </div>
    );
}

export default Rolling;
